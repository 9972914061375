import * as React from 'react';
import { HeadProps } from 'gatsby';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { Title, TitleHighlighted, Text } from '../styles/typography';
import { Container, PatternImage, SeperatorLine } from '../styles/layout';
import {
	ImageSpace1,
	ImageSpace2,
	ImageSpace3,
	ImageSpace4,
	ImageSpace5,
	ImageSpace6,
	ImageSpace7,
	ImageSpace8,
	ImageSpace9,
} from '../assets/images/space';
import { ImageSocialSpace } from '../assets/images/social';

export interface Props {
	location: Location;
}

const SectionWithTextAndImages = styled.section<{ marginBottom?: number }>`
	margin-bottom: ${(props) => props.marginBottom && `${props.marginBottom}px`};
`;

const SectionWithPattern = styled.section`
	position: relative;
	margin-top: 80px;
`;

const ImageWrapper = styled.div`
	position: relative;

	img {
		width: 100%;
		height: auto;
		border-radius: 16px;
	}

	.icon {
		position: absolute;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 183px;
		height: 183px;
		background-color: rgb(234, 172, 5);
		color: rgb(255, 255, 255);
		border: 4px solid rgb(255, 255, 255);
		border-radius: 100%;
		box-shadow: 0px 8px 40px rgba(0, 148, 68, 0.1);
		top: -91.5px;
		font-size: 80px;
		z-index: 1;

		&.left {
			left: 65px;
		}

		&.right {
			right: 65px;
		}

		&-chair {
			background-color: rgb(0, 148, 68);
		}
		&-building,
		&-dumbell {
			background-color: rgb(31, 34, 34);
			font-size: 55px;
		}
		&-slide {
			background-color: rgb(89, 123, 174);
		}
		&-yoga {
			background-color: rgb(89, 169, 174);
			font-size: 67px;
		}

		${down('sm')} {
			width: 90px;
			height: 90px;
			font-size: 45px;
			top: -45px;

			&-building,
			&-dumbell {
				font-size: 27px;
			}
			&-yoga,
			&-slide {
				font-size: 35px;
			}

			&.left {
				left: 25px;
			}

			&.right {
				right: 25px;
			}
		}
	}
`;

const LeftPositionedText = styled(Text)`
	text-align: left;
	padding-right: 350px;
	margin-bottom: 80px;

	${down('lg')} {
		padding-right: 0;
		margin-bottom: 150px;
	}

	${down('sm')} {
		margin-bottom: 75px;
	}
`;

const RightPositionedText = styled(Text)`
	text-align: left;
	padding-left: 350px;
	margin-bottom: 80px;

	${down('lg')} {
		padding-left: 0;
		margin-bottom: 150px;
	}

	${down('sm')} {
		margin-bottom: 75px;
	}
`;

export const SpacePage = ({ location }: Props) => {
	return (
		<Layout location={location}>
			<Container>
				<Title>
					<TitleHighlighted>Ustvarjalno okolje</TitleHighlighted> usmerjeno v razvoj potencialov posameznika
					na vseh področjih življenja
				</Title>
			</Container>

			<SectionWithPattern>
				<PatternImage />
				<Container>
					<ImageWrapper>
						<ImageSpace1 />
					</ImageWrapper>
				</Container>
			</SectionWithPattern>

			<Container>
				<Text>
					Rdeča nit prostora Tehnološkega parka je ustvariti okolje, ki bo omogočalo{' '}
					<strong>rast in razvoj posameznika</strong> - ne le na tehnološkem področju, ampak na{' '}
					<strong>vseh področjih našega življenja</strong>. S to mislijo smo pristopili tudi k opremljanju
					širšega prostora.
				</Text>
				<SeperatorLine />
			</Container>

			<SectionWithTextAndImages>
				<Container>
					<LeftPositionedText>
						Tako sprehod po Tehnološkem parku najprej zaokrožijo vsebine na{' '}
						<strong>“zidovih znanja”</strong>, umeščene na stopnišča po celotni stavbi in knjižne kotičke.
						Izbrana dela priznanih avtorjev, ki pokrivajo različna področja našega življenja, so na voljo na
						izposojo in v branje vsem, ki želijo vlagati vase, tako na profesionalni kot na osebni ravni.
					</LeftPositionedText>
					<ImageWrapper>
						<span className="icon icon-book right"></span>
						<ImageSpace2 />
					</ImageWrapper>
					<SeperatorLine />
				</Container>
			</SectionWithTextAndImages>

			<SectionWithTextAndImages>
				<Container>
					<RightPositionedText>
						Samo stavbo sestavljajo <strong>4 etaže</strong>, znotraj katerih smo pisarne zasnovali kot
						samostojna enote, ki nudijo zasebnost posameznim oddelkom.
					</RightPositionedText>
					<ImageWrapper>
						<span className="icon icon-chair left"></span>
						<ImageSpace3 />
					</ImageWrapper>
					<SeperatorLine />
				</Container>
			</SectionWithTextAndImages>

			<SectionWithTextAndImages>
				<Container>
					<LeftPositionedText>
						Po celotnem delovnem okolju so premišljeno umeščeni <strong>številni delovni kotički</strong>,
						ki nudijo zasebnost večjim in manjšim ekipam.
					</LeftPositionedText>
					<ImageWrapper>
						<span className="icon icon-phone-booth right"></span>
						<ImageSpace4 />
					</ImageWrapper>
					<SeperatorLine />
				</Container>
			</SectionWithTextAndImages>

			<SectionWithTextAndImages>
				<Container>
					<RightPositionedText>
						Popolnoma nov element, dodan obstoječemu tlorisu stavbe, je <strong>zastekljen paviljon</strong>
						, ki se odpira proti zunanjosti in povezuje notranjost stare vile z zelenim parkom v okolici.
						<br />
						<br />
						Prostor je namensko ločen od pisarniškega dela stavbe in zasnovan z mislijo, da nudi prebivalcem
						možnost za druženje med tednom za razna{' '}
						<strong>izobraževanja, knjižni klub ter dogodke za izmenjavo znanj in izkušenj</strong>, ob
						popoldnevih in petkih pa tudi za sproščeno druženje ob igrah.
					</RightPositionedText>
					<ImageWrapper>
						<span className="icon icon-building left"></span>
						<ImageSpace5 />
					</ImageWrapper>
					<SeperatorLine />
				</Container>
			</SectionWithTextAndImages>

			<SectionWithTextAndImages>
				<Container>
					<LeftPositionedText>
						Kot vsak park, ima tudi naš svoj <strong>“playground”</strong>. Kot posebnost je vanj umeščen
						tobogan, ki povezuje stari del stavbe z novim in poskrbi za malce adrenalina ter sprostitev med
						delom. Nismo pozabili tudi na <strong>otroški kotiček in prostor za ustvarjanje</strong>, kjer
						se lahko v popoldanskih urah staršem pridružijo tudi otroci.
					</LeftPositionedText>
					<ImageWrapper>
						<span className="icon icon-slide right"></span>
						<ImageSpace6 />
					</ImageWrapper>
					<SeperatorLine />
				</Container>
			</SectionWithTextAndImages>

			<SectionWithTextAndImages>
				<Container>
					<RightPositionedText>
						Sončna mansarda stavbe ne nudi le čudovitih razgledov na grad in okoliški park, temveč tudi{' '}
						<strong>Zen prostor</strong>, namenjen vsem, ki se želijo med delom odpočiti, izvesti kratko
						meditacijo, izvesti nežno vadbo ali začeti svoja jutra v spokojnosti.
					</RightPositionedText>
					<ImageWrapper>
						<span className="icon icon-yoga left"></span>
						<ImageSpace7 />
					</ImageWrapper>
					<SeperatorLine />
				</Container>
			</SectionWithTextAndImages>

			<SectionWithTextAndImages>
				<Container>
					<LeftPositionedText>
						V najnižjo etažo hiše, ki je med obnovo odkrila številne najdbe, smo umestili{' '}
						<strong>sodobno opremljen fitnes</strong>, kjer bo vsem članom tehnološkega parka na voljo
						osebni trener.
					</LeftPositionedText>
					<ImageWrapper>
						<span className="icon icon-dumbell right"></span>
						<ImageSpace8 />
					</ImageWrapper>
					<SeperatorLine />
				</Container>
			</SectionWithTextAndImages>

			<SectionWithTextAndImages marginBottom={42}>
				<Container>
					<RightPositionedText>
						Zunanji del stavbe krasijo trije francoski balkončki ter velika pohodna terasa, ki bo predvsem v
						prehodnih sezonah čudovita priložnost za umik in delo med zelenimi krošnjami.
					</RightPositionedText>
					<ImageWrapper>
						<span className="icon icon-sun left"></span>
						<ImageSpace9 />
					</ImageWrapper>
				</Container>
			</SectionWithTextAndImages>
		</Layout>
	);
};

export const Head = (props: HeadProps) => {
	return (
		<Seo
			title={'Prostor & bivanje'}
			description={
				'Ustvarjalno okolje usmerjeno v rast in razvoj posameznika - ne le na tehnološkem področju, ampak na vseh področjih našega življenja.'
			}
			image={ImageSocialSpace}
			locationPathName={props.location.pathname}
		/>
	);
};

export default SpacePage;
