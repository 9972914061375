import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const ImageSpace1 = () => {
	return <StaticImage src="./1.jpg" alt="Druženje ob uradni otvoritvi Tehnološkega parka Ptuj" />;
};

const ImageSpace2 = () => {
	return <StaticImage src="./2.jpg" alt="Zidovi znanja / knjižni kotiček" />;
};

const ImageSpace3 = () => {
	return <StaticImage src="./3.jpg" alt="Open space koncept delovnih prostorov" />;
};

const ImageSpace4 = () => {
	return <StaticImage src="./4.jpg" alt="Govorilnice za umik v zasebnost / sejna soba za manjše ekipe" />;
};

const ImageSpace5 = () => {
	return (
		<StaticImage src="./5.jpg" alt="Jedilnica v lounge prostoru / zastekljeni pavilijon Tehnološkega parka Ptuj" />
	);
};

const ImageSpace6 = () => {
	return <StaticImage src="./6.jpg" alt="Sprostitev ob delu z rocčnim nogometom / playground in otroški kotiček" />;
};

const ImageSpace7 = () => {
	return <StaticImage src="./7.jpg" alt="Bralni kotiček / zen soba za sprostitev in meditacijo" />;
};

const ImageSpace8 = () => {
	return <StaticImage src="./8.jpg" alt="Sodobno opremljen fitnes" />;
};

const ImageSpace9 = () => {
	return <StaticImage src="./9.jpg" alt="Zunanji del prenovljene stavbe z pohodno teraso" />;
};

export {
	ImageSpace1,
	ImageSpace2,
	ImageSpace3,
	ImageSpace4,
	ImageSpace5,
	ImageSpace6,
	ImageSpace7,
	ImageSpace8,
	ImageSpace9,
};
